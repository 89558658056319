<template>
    <div>
        <ts-page-title
            :title="$t('coaMapping.pageTitle')"
            :breadcrumb="[
                { text: $t('home'), href: '/' },
                {
                    text: $t('coaMapping.pageTitle'),
                    active: true
                }
            ]"
        />
        <ts-panel>
            <ts-panel-wrapper
                class="tw-flex tw-items-center tw-justify-between"
            >
                <Button type="info" ghost @click="addNew">{{
                    $t("addNew")
                }}</Button>
                <div class="tw-justify-end tw-space-x-2">
                    <Input
                        search
                        v-model="search"
                        :placeholder="$t('coaMapping.search')"
                        :style="width"
                        @on-focus="() => (width = 'width: 500px')"
                        @on-blur="() => (width = 'width: 300px')"
                    />
                </div>
            </ts-panel-wrapper>
            <Table
                :columns="columns"
                :data="resources"
                size="small"
                :loading="loading"
                max-height="700"
                stripe
            >
                <template slot-scope="{ row }" slot="is_active">
                    <Icon
                        v-if="row.is_active"
                        type="ios-checkmark-circle-outline text-success"
                        size="20"
                    />
                    <Icon
                        v-else
                        type="ios-close-circle-outline text-danger"
                        size="20"
                    />
                </template>
                <template slot-scope="{ row }" slot="loan_type_id">
                    {{ row.loan_type ? row.loan_type.loan_type_en : "" }}
                </template>
                <template slot-scope="{ row }" slot="tran_id">
                    {{
                        row.transaction_type
                            ? row.transaction_type.tran_name
                            : ""
                    }}
                </template>
                <template slot-scope="{ row }" slot="created_by">
                    {{
                        row.created_by
                            ? row.created_by.employee
                                ? row.created_by.employee.employee_name_en
                                : row.created_by.user_name
                            : ""
                    }}
                </template>
                <template slot-scope="{ row }" slot="currency_id">
                    {{ row.currency ? row.currency.currency_name_en : "" }}
                </template>
                <template slot-scope="{ row }" slot="action">
                    <a
                        href="#"
                        class="text-primary ml-4"
                        @click.prevent="onEdit(row)"
                        v-tooltip="$t('edit')"
                    >
                        <Icon type="ios-create" size="20" />
                    </a>
                    <Poptip
                        :title="$t('are_you_sure_to_delete')"
                        :transfer="true"
                        width="240"
                        v-if="!row._deleting"
                        @on-popper-show="() => model.deleted_reason = ''"
                    >
                        <a
                            class="ml-2 text-danger"
                            v-tooltip="$t('delete')"
                            :disabled="row._deleting"
                        >
                            <i
                                class="fas fa-circle-notch fa-spin"
                                v-if="row._deleting"
                            ></i>
                            <Icon type="ios-trash" size="20" v-else />
                        </a>
                        <div slot="content">
                            <label class="required">{{
                                $t("coaMapping.reason")
                            }}</label>
                            <input
                                v-model.trim="model.deleted_reason"
                                type="text"
                                class="form-control"
                                :class="{
                                    'is-invalid':
                                        errors.has('deleted_reason') ||
                                        $v.model.deleted_reason.$error
                                }"
                            />
                            <span
                                class="invalid-feedback"
                                v-if="reasonErrors.length > 0"
                                >{{ reasonErrors[0] }}</span
                            >
                            <div
                                class="invalid-feedback"
                                v-if="errors.has('deleted_reason')"
                            >
                                {{ errors.first("deleted_reason") }}
                            </div>
                            <div class="tw-mt-2 tw-text-right">
                                <a
                                    class="ivu-btn ivu-btn-primary ivu-btn-small"
                                    @click.prevent="onDelete(row)"
                                >
                                    <span>OK</span>
                                </a>
                            </div>
                        </div>
                    </Poptip>
                    <a
                        v-else
                        class="ml-2 text-danger"
                        v-tooltip="$t('delete')"
                        :disabled="row._deleting"
                    >
                        <i
                            class="fas fa-circle-notch fa-spin"
                            v-if="row._deleting"
                        ></i>
                        <Icon type="ios-trash" size="20" v-else />
                    </a>
                </template>
            </Table>
            <div class="tw-flex tw-items-center tw-justify-end tw-p-4">
                <ts-i-pagination
                    v-model="pagination"
                    @navigate="fetchData"
                ></ts-i-pagination>
            </div>
            <!-- form-action -->
            <Modal
                v-model="showForm"
                draggable
                sticky
                scrollable
                :mask="false"
                :footer-hide="true"
                :z-index="1022"
                :title="$t('coaMapping.pageTitle')"
                width="1200"
            >
                <from-action
                    ref="form_action"
                    @cancel="clearEdit"
                    @fetchData="fetchData"
                />
            </Modal>
        </ts-panel>
    </div>
</template>

<script>
import { debounce } from "lodash";
import { mapState } from "vuex";
import FromAction from "./form";
import expandRow from "./table-expand.vue";
import { required } from "vuelidate/lib/validators";
import { Errors } from "form-backend-validation";

export default {
    name: "coaMappingIndex",
    components: {
        FromAction
    },
    data() {
        return {
            loading: false,
            showForm: false,
            width: "width: 300px",
            errors: new Errors(),
            model: {
                deleted_reason: ""
            }
        };
    },
    validations: {
        model: {
            deleted_reason: { required }
        },
        form: ["model.deleted_reason"]
    },
    computed: {
        ...mapState("accounting/coaMapping", ["resources", "pagination"]),
        search: {
            get() {
                return this.$store.state.accounting.coaMapping.search;
            },
            set(newValue) {
                this.$store.commit(
                    "accounting/coaMapping/SET_SEARCH",
                    newValue
                );
                this.$store.commit("accounting/coaMapping/RESET_CURRENT_PAGE");
            }
        },
        reasonErrors() {
            const errors = [];
            if (!this.$v.model.deleted_reason.$error) return errors;
            !this.$v.model.deleted_reason.required && errors.push("Required");
            return errors;
        },
        columns() {
            return [
                {
                    type: "expand",
                    render: (h, params) => {
                        return h(expandRow, {
                            props: {
                                row: params.row
                            }
                        });
                    },
                    width: 30
                },
                {
                    title: this.$t("coaMapping.loanType"),
                    slot: "loan_type_id",
                    sortable: true,
                    width: 250
                },
                {
                    title: this.$t("coaMapping.transactionType"),
                    slot: "tran_id",
                    sortable: true,
                    width: 250
                },
                {
                    title: this.$t("coaMapping.currency"),
                    slot: "currency_id",
                    sortable: true,
                    width: 200
                },
                {
                    title: this.$t("coaMapping.remarks"),
                    key: "remarks",
                    width: 350
                },
                {
                    title: this.$t("coaMapping.createdBy"),
                    slot: "created_by",
                    sortable: true,
                    width: 250
                },
                {
                    title: this.$t("coaMapping.createdDate"),
                    key: "created_date",
                    sortable: true,
                    align: "center",
                    width: 200
                },
                {
                    title: this.$t("actions"),
                    key: "action",
                    slot: "action",
                    fixed: "right",
                    align: "center",
                    className: "tw-space-x-2",
                    width: 90
                }
            ];
        }
    },
    methods: {
        fetchData(attributes) {
            this.loading = true;
            this.$store
                .dispatch("accounting/coaMapping/fetch", { ...attributes })
                .then(() => {
                    this.loading = false;
                })
                .catch(error => {
                    this.loading = false;
                    this.notice({ type: "error", text: error.message });
                });
        },
        onEdit(record) {
            this.$refs.form_action.clearInput();
            this.$store.commit("accounting/coaMapping/SET_EDIT_DATA", record);
            this.showForm = true;
            this.$refs.form_action.fetchResource();
        },
        clearEdit() {
            this.showForm = false;
            this.$refs.form_action.clearInput();
            this.$store.commit("accounting/coaMapping/SET_EDIT_DATA", {});
        },
        addNew() {
            this.$store.commit("accounting/coaMapping/SET_EDIT_DATA", {});
            this.showForm = true;
            this.$refs.form_action.fetchResource();
            this.$refs.form_action.clearInput();
        },
        async onDelete(record) {
            this.$v.form.$touch();
            if (this.$v.form.$invalid) return;

            record._deleting = true;
            this.$store
                .dispatch("accounting/coaMapping/destroy", {id: record.mapping_id, data: this.model})
                .then(response => {
                    this.fetchData();
                    this.notice({
                        type: "success",
                        text: response.message
                    });
                })
                .catch(error => {
                    this.notice({ type: "error", text: error.message });
                })
                .finally(() => {
                    record._deleting = false;
                    this.model.deleted_reason = "";
                });
        },
        notice(not) {
            this.$Notice[not.type]({
                title: "CoA MAPPING",
                desc: not.text
            });
        }
    },
    watch: {
        search: debounce(function(search) {
            this.fetchData({ search });
        }, 500)
    },
    beforeRouteLeave(to, from, next) {
        this.$store.commit("accounting/coaMapping/RESET_STATE");
        this.$store.commit("accounting/coaMapping/CLEAR_STATE");
        next();
    },
    beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.fetchData();
        });
    }
};
</script>
