var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('Table',{attrs:{"columns":_vm.columns,"data":_vm.resources,"size":"small","stripe":""},scopedSlots:_vm._u([{key:"account_number",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.account ? row.account.account_number : "")+" ")]}},{key:"account_name",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.account ? row.account.account_name_en : "")+" "+_vm._s(row.account.account_name_kh ? "|" + row.account.account_name_kh : "")+" ")]}},{key:"mapping_side",fn:function(ref){
var row = ref.row;
return [(row.mapping_side == 'Debit')?_c('span',{staticClass:"badge bg-success tw-mr-1 tw-capitalize"},[_vm._v(" "+_vm._s(row.mapping_side)+" ")]):_c('span',{staticClass:"badge bg-danger tw-mr-1 tw-capitalize"},[_vm._v(" "+_vm._s(row.mapping_side)+" ")])]}},{key:"is_use_custom_source_value",fn:function(ref){
var row = ref.row;
return [(row.is_use_custom_source_value)?_c('span',{staticClass:"badge bg-success tw-mr-1 tw-capitalize"},[_vm._v(" Yes ")]):_c('span',{staticClass:"badge bg-secondary tw-mr-1 tw-capitalize"},[_vm._v(" No ")])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }