var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ts-page-title',{attrs:{"title":_vm.$t('coaMapping.pageTitle'),"breadcrumb":[
            { text: _vm.$t('home'), href: '/' },
            {
                text: _vm.$t('coaMapping.pageTitle'),
                active: true
            }
        ]}}),_c('ts-panel',[_c('ts-panel-wrapper',{staticClass:"tw-flex tw-items-center tw-justify-between"},[_c('Button',{attrs:{"type":"info","ghost":""},on:{"click":_vm.addNew}},[_vm._v(_vm._s(_vm.$t("addNew")))]),_c('div',{staticClass:"tw-justify-end tw-space-x-2"},[_c('Input',{style:(_vm.width),attrs:{"search":"","placeholder":_vm.$t('coaMapping.search')},on:{"on-focus":function () { return (_vm.width = 'width: 500px'); },"on-blur":function () { return (_vm.width = 'width: 300px'); }},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('Table',{attrs:{"columns":_vm.columns,"data":_vm.resources,"size":"small","loading":_vm.loading,"max-height":"700","stripe":""},scopedSlots:_vm._u([{key:"is_active",fn:function(ref){
        var row = ref.row;
return [(row.is_active)?_c('Icon',{attrs:{"type":"ios-checkmark-circle-outline text-success","size":"20"}}):_c('Icon',{attrs:{"type":"ios-close-circle-outline text-danger","size":"20"}})]}},{key:"loan_type_id",fn:function(ref){
        var row = ref.row;
return [_vm._v(" "+_vm._s(row.loan_type ? row.loan_type.loan_type_en : "")+" ")]}},{key:"tran_id",fn:function(ref){
        var row = ref.row;
return [_vm._v(" "+_vm._s(row.transaction_type ? row.transaction_type.tran_name : "")+" ")]}},{key:"created_by",fn:function(ref){
        var row = ref.row;
return [_vm._v(" "+_vm._s(row.created_by ? row.created_by.employee ? row.created_by.employee.employee_name_en : row.created_by.user_name : "")+" ")]}},{key:"currency_id",fn:function(ref){
        var row = ref.row;
return [_vm._v(" "+_vm._s(row.currency ? row.currency.currency_name_en : "")+" ")]}},{key:"action",fn:function(ref){
        var row = ref.row;
return [_c('a',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.$t('edit')),expression:"$t('edit')"}],staticClass:"text-primary ml-4",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.onEdit(row)}}},[_c('Icon',{attrs:{"type":"ios-create","size":"20"}})],1),(!row._deleting)?_c('Poptip',{attrs:{"title":_vm.$t('are_you_sure_to_delete'),"transfer":true,"width":"240"},on:{"on-popper-show":function () { return _vm.model.deleted_reason = ''; }}},[_c('a',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.$t('delete')),expression:"$t('delete')"}],staticClass:"ml-2 text-danger",attrs:{"disabled":row._deleting}},[(row._deleting)?_c('i',{staticClass:"fas fa-circle-notch fa-spin"}):_c('Icon',{attrs:{"type":"ios-trash","size":"20"}})],1),_c('div',{attrs:{"slot":"content"},slot:"content"},[_c('label',{staticClass:"required"},[_vm._v(_vm._s(_vm.$t("coaMapping.reason")))]),_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.model.deleted_reason),expression:"model.deleted_reason",modifiers:{"trim":true}}],staticClass:"form-control",class:{
                                'is-invalid':
                                    _vm.errors.has('deleted_reason') ||
                                    _vm.$v.model.deleted_reason.$error
                            },attrs:{"type":"text"},domProps:{"value":(_vm.model.deleted_reason)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.model, "deleted_reason", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),(_vm.reasonErrors.length > 0)?_c('span',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(_vm.reasonErrors[0]))]):_vm._e(),(_vm.errors.has('deleted_reason'))?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.errors.first("deleted_reason"))+" ")]):_vm._e(),_c('div',{staticClass:"tw-mt-2 tw-text-right"},[_c('a',{staticClass:"ivu-btn ivu-btn-primary ivu-btn-small",on:{"click":function($event){$event.preventDefault();return _vm.onDelete(row)}}},[_c('span',[_vm._v("OK")])])])])]):_c('a',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.$t('delete')),expression:"$t('delete')"}],staticClass:"ml-2 text-danger",attrs:{"disabled":row._deleting}},[(row._deleting)?_c('i',{staticClass:"fas fa-circle-notch fa-spin"}):_c('Icon',{attrs:{"type":"ios-trash","size":"20"}})],1)]}}])}),_c('div',{staticClass:"tw-flex tw-items-center tw-justify-end tw-p-4"},[_c('ts-i-pagination',{on:{"navigate":_vm.fetchData},model:{value:(_vm.pagination),callback:function ($$v) {_vm.pagination=$$v},expression:"pagination"}})],1),_c('Modal',{attrs:{"draggable":"","sticky":"","scrollable":"","mask":false,"footer-hide":true,"z-index":1022,"title":_vm.$t('coaMapping.pageTitle'),"width":"1200"},model:{value:(_vm.showForm),callback:function ($$v) {_vm.showForm=$$v},expression:"showForm"}},[_c('from-action',{ref:"form_action",on:{"cancel":_vm.clearEdit,"fetchData":_vm.fetchData}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }